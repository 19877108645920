import NextImage from 'next/image'
import NextLink from 'next/link'
import { ISbStoryData } from 'storyblok-js-client'
import styled, { css } from 'styled-components'

import { Button, Text } from 'common/UI'
import {
  BlogPostStoryblok,
  PatientStoryStoryblok,
  ServiceStoryblok,
} from 'common/types'
import {
  getImageAttributes,
  sortServicesTypesAlphabetically,
} from 'common/utils/content'
import { formatDateLocale } from 'common/utils/datetime'
import { removeTrailingSlash } from 'common/utils/string'
import { addAlphaToColor, getColorFromObject } from 'common/utils/style'
import { useTranslation } from 'lib/i18n'

import { TagList } from '../TagList'

type Props = {
  item: ISbStoryData<BlogPostStoryblok> | ISbStoryData<PatientStoryStoryblok>
  variant: 'blog' | 'story'
}

export const getThumbCategories = (content: unknown): string[] => {
  if (Array.isArray(content)) {
    return content.map(
      (cat: { content: { type: string } }) => cat.content?.type
    )
  }
  if (typeof content === 'string' && content.length > 0) {
    return [content]
  }

  if (typeof content === 'object' && content && 'content' in content) {
    return [(content as { content: { type: string } }).content.type]
  }

  return []
}

export const BigThumb = ({ item, ...props }: Props): JSX.Element => {
  const { locale, i18n } = useTranslation()

  const serviceColors = (type: ServiceStoryblok['type']) => {
    switch (type) {
      case 'diagnosis':
        return {
          bgcolor: 'palette.yellow.light',
          opacity: 40,
        }
      case 'treatment':
        return {
          bgcolor: 'palette.pink.100',
          opacity: 100,
        }
      case 'donation':
        return {
          bgcolor: 'palette.orange.light',
          opacity: 40,
        }
      case 'lgbtqia+':
        return {
          bgcolor: 'palette.hyacinth.light',
          opacity: 100,
        }
      case 'preservation':
        return {
          bgcolor: 'palette.aqua.light',
          opacity: 100,
        }
      case 'condition':
        return {
          bgcolor: 'palette.violet.light',
          opacity: 100,
        }
      case 'advice':
        return {
          bgcolor: 'palette.green.light',
          opacity: 100,
        }
      case 'news':
        return {
          bgcolor: 'palette.lime.light',
          opacity: 100,
        }

      default:
        return {
          bgcolor: 'palette.blue.500',
          opacity: 5,
        }
    }
  }

  const categories = Array.from(
    new Set(
      item.content.component === 'patient-story'
        ? getThumbCategories(item.content.categories)
        : getThumbCategories(item.content.services)
    )
  ) as Array<ServiceStoryblok['type']>

  const serviceColor = serviceColors(categories[0])

  return (
    <Thumb
      {...props}
      colors={serviceColor}
      href={removeTrailingSlash(`/${item.full_slug}`)}
    >
      <Content>
        <div>
          <Meta>
            {categories?.length > 0 && (
              <TagList
                hasHiddenTags
                hasColorVariants
                maxVisibleTags={2}
                tags={sortServicesTypesAlphabetically(categories)}
              />
            )}

            <Text as="span" variant="sixteen">
              {item.first_published_at
                ? formatDateLocale(item.first_published_at, locale)
                : ''}
            </Text>
          </Meta>
          <Text
            as="h2"
            variant="title/large"
            css={{
              marginTop: '0.75rem',
              wordBreak: 'break-word',
            }}
          >
            {item.content.title}
          </Text>
        </div>

        <ButtonV2Styled
          variant="ghost"
          rightIcon="arrow-right"
          css={{ width: 'fit-content', fontSize: '1rem' }}
        >
          {i18n('readStory')}
        </ButtonV2Styled>
      </Content>

      {item.content.cover_image && item.content.cover_image.filename && (
        <ImageHolder>
          <NextImage
            src={
              getImageAttributes(
                item.content.cover_image,
                '800x400/smart/filters:format(webp)'
              ).src
            }
            alt={getImageAttributes(item.content.cover_image).alt}
            fill
            sizes="700"
            style={{ objectFit: 'cover', height: '100%' }}
          />
        </ImageHolder>
      )}
    </Thumb>
  )
}

export const Thumb = styled(NextLink)<{
  colors?: {
    bgcolor: string
    opacity: number
  }
}>`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  color: inherit;
  background-color: ${({ theme, colors }) => {
    const defaultColor = theme.colors.foreground.default
    const defaultOpacity = 100
    const themeColor = colors
      ? getColorFromObject(theme.colors, colors.bgcolor)
      : defaultColor

    return css`
      ${addAlphaToColor(
        themeColor || defaultColor,
        colors?.opacity || defaultOpacity
      )};
    `
  }};

  border-radius: 1rem;
  overflow: hidden;

  ${({ theme }) => theme.media.md} {
    flex-direction: row;
    height: 29.0625rem;
  }
`

const ImageHolder = styled.div`
  position: relative;
  padding-bottom: 63%;
  margin: 0.5rem;
  border-radius: 1rem;
  overflow: hidden;

  ${({ theme }) => theme.media.md} {
    width: 57%;
    padding-bottom: 35%;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.75rem;
  max-width: 27.875rem;

  ${({ theme }) => theme.media.md} {
    padding: 2rem;
  }
`

const Meta = styled.div`
  display: flex;
  align-items: center;
`

const ButtonV2Styled = styled(Button)`
  display: none;
  ${({ theme }) => theme.media.md} {
    display: block;
  }
`
