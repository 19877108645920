import NextLink from 'next/link'

import { Icon } from './Icon'

export const BREADCRUMBS_MIN_LEVEL = 2

export type Breadcrumb = {
  breadcrumb: string
  href: string
}

type BreadcrumbsProps = {
  breadcrumbs: Breadcrumb[]
}

export const Breadcrumbs = ({ breadcrumbs }: BreadcrumbsProps) => {
  return breadcrumbs ? (
    <div className="w-full ">
      <nav
        aria-label="breadcrumb"
        className="text-foreground bg-background overflow-hidden px-5 lg:px-20 w-full mx-auto max-w-screen-desktop"
      >
        <ul className="hidden md:flex flex-wrap gap-y-4 h-15 items-center">
          {breadcrumbs?.map(({ breadcrumb, href }, index) => {
            const isLast = breadcrumbs.length - 1 === index
            return (
              <li
                key={index}
                className="flex items-center justify-center gap-1 mr-1 last:mr-0"
              >
                <NextLink
                  href={href}
                  className="text-foreground/60 text-sixteen font-regular capitalize whitespace-nowrap hover:text-foreground/80 focus-visible:text-foreground/80"
                >
                  {breadcrumb}
                </NextLink>

                {!isLast && (
                  <Icon
                    size={20}
                    icon="chevron-right"
                    styles={{ opacity: 0.7 }}
                  />
                )}
              </li>
            )
          })}
        </ul>

        {breadcrumbs?.length > BREADCRUMBS_MIN_LEVEL && (
          <div className="md:hidden text-foreground text-sm flex items-center gap-1 font-regular py-5">
            <Icon size={16} icon="chevron-left" styles={{ opacity: 0.6 }} />
            <NextLink
              href={breadcrumbs[breadcrumbs.length - 2].href}
              className="text-foreground/80 text-sixteen font-regular capitalize whitespace-nowrap hover:text-foreground/100 focus-visible:text-foreground/100"
            >
              {breadcrumbs[breadcrumbs.length - 2].breadcrumb}
            </NextLink>
          </div>
        )}
      </nav>
    </div>
  ) : (
    <></>
  )
}
