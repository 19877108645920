import styled from 'styled-components'

import { addAlphaToColor, getColorFromObject } from 'common/utils/style'

import { Text, TextProps } from '../UI/Text'
import { Icon, IconOptions } from './Icon'

export type TagProps = {
  label: string
  color?: TextProps['color']
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  icon?: IconOptions
}

export const Tag = ({
  label,
  color,
  icon,
  onMouseEnter,
  onMouseLeave,
  ...props
}: TagProps): JSX.Element => {
  return (
    <StyledTag
      as="span"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      variant="sixteen/regular"
      color={color}
      {...props}
    >
      {icon && <StyledIcon size={16} icon={icon} />}
      {label}
    </StyledTag>
  )
}

const StyledTag = styled(Text)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: fit-content;

  padding: 0.25rem 0.625rem;
  border-radius: 0.5rem;
  gap: 0.25rem;

  background-color: ${({ theme, color }) => {
    const defaultColor = theme.colors.foreground.default
    const themeColor = color
      ? getColorFromObject(theme.colors, color)
      : defaultColor
    return color
      ? themeColor || defaultColor
      : addAlphaToColor(defaultColor, 10)
  }};
  color: ${({ theme }) => theme.colors.foreground.default};
`

const StyledIcon = styled(Icon)`
  color: ${({ theme }) => addAlphaToColor(theme.colors.foreground.default, 60)};
  margin-right: 0.25rem;
`
